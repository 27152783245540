import React from "react";

function PreviousIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      fill="none"
      viewBox="0 0 16 28"
    >
      <path fill="#000" d="M16 4L6 14l10 10-2 4L0 14 14 0l2 4z"></path>
    </svg>
  );
}

export default PreviousIcon;
