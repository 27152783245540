
import { MouseEventHandler} from 'react'
import { classnames, TArg } from "tailwindcss-classnames";
import {NextIcon, PreviousIcon} from '../Icons'

type ArrowProps = {
    onClick?: MouseEventHandler<HTMLDivElement>
    twClassName?: TArg
}

const arrowStyle = classnames(
    "absolute", 
    "bottom-0",
    "xl:top-1/2",
    "xl:bottom-auto",
    "z-10",
    "translate-y-full", 
    "scale-75",
    "sm:scale-100",
    "cursor-pointer"
)

export const CarouselNext = ({onClick, twClassName}: ArrowProps) => {
    return (
        <div className={classnames(
            arrowStyle,
            "left-5",
            "sm:left-6", 
            "md:left-0",
            "right-auto", 
            "xl:-left-8",
            twClassName
        )} onClick={onClick}>
            <PreviousIcon />
        </div>
    )
} 

export const CarouselPrev = ({onClick, twClassName}: ArrowProps) => {
    return (
        <div className={classnames(
            arrowStyle, 
            "right-5",
            "sm:right-6",
            "md:right-0",
            "xl:-right-8",
            "left-auto", 
            twClassName
        )} onClick={onClick}>
            <NextIcon  />
        </div>
    )
}
