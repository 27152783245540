import React from "react";

function NextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      fill="none"
      viewBox="0 0 16 28"
    >
      <path fill="#000" d="M0 4l10 10L0 24l2 4 14-14L2 0 0 4z"></path>
    </svg>
  );
}

export default NextIcon;
